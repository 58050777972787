import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import SectionTitle from '../../../components/shared/section-title'
import Blog from '../../../components/blog/layout-two'
import Pagination from '../../../components/pagination'
import {PostsWrap, PostContent} from './featured-posts-list.stc'
 
const RecentPostArea = (props) => {
    const blogData = useStaticQuery(graphql `
        query RecentPostQuery {
            allMarkdownRemark(filter: {frontmatter: {is_featured: {eq: true}}}, sort: {order: DESC, fields: frontmatter___date}, limit: 10) {
                totalCount
                edges {
                    node {
                        fields {
                            slug
                            dateSlug
                            postID
                        }
                        frontmatter {
                            category
                            type
                            date(formatString: "LL")
                            format
                            tags
                            title
                            image {
                                childImageSharp {
                                    fluid(maxWidth: 510, maxHeight: 400, cropFocus: CENTER, quality: 100, srcSetBreakpoints: 6) {
                                        ...GatsbyImageSharpFluid_withWebp
                                        presentationWidth
                                        presentationHeight
                                    }
                                }
                            }
                            images {
                                childImageSharp {
                                    fluid(maxWidth: 510, maxHeight: 400, cropFocus: CENTER, quality: 100, srcSetBreakpoints: 6) {
                                        ...GatsbyImageSharpFluid_withWebp
                                        presentationWidth
                                        presentationHeight
                                    }
                                }
                            }
                        }
                        excerpt(pruneLength: 165, format: PLAIN, truncate: true)
                    }
                }
            }
        }      
    `);
    const blogs = blogData.allMarkdownRemark.edges;
    const {sectionTitleStyle, blogStyle} = props;
    const {totalCount} = blogData.allMarkdownRemark;
    return (
        <PostsWrap>
            <SectionTitle
                {...sectionTitleStyle}
                title="My Featured Work"
            />
            <PostContent>
                {blogs.map(blog => (
                    <Blog
                        {...blogStyle}
                        key={blog.node.fields.slug}
                        content={{
                            ...blog.node.fields, 
                            ...blog.node.frontmatter,
                            excerpt: blog.node.excerpt
                        }}
                    />
                ))}
            </PostContent>
        </PostsWrap>
    )
}

RecentPostArea.propTypes = {
    sectionTitleStyle: PropTypes.object,
    blogStyle: PropTypes.object
}

RecentPostArea.defaultProps = {
    sectionTitleStyle: {
        mb: '46px'
    },
    blogStyle: {
        mb: '50px'
    }
}

export default RecentPostArea
