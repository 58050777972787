import React from 'react'
import {Container, Row, Col} from 'reactstrap'
import FeaturedPostsList from '../featured-posts-list'
import Sidebar from '../../global/sidebar'
import {MainWrapper} from './main-area.stc'

const MainArea = () => {
    return (
        <MainWrapper>
            <Container>
                <Row>
                    <Col>                        
                        <FeaturedPostsList/>
                    </Col>
                </Row>
            </Container>
        </MainWrapper>
    )
}

export default MainArea
