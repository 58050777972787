import styled from 'styled-components';
import BackgroundImage from 'gatsby-background-image'
import BannerShape from '../../../assets/img/home-one-banner-shape.png'
import {device} from '../../../theme'

export const HeroWrapper = styled.section `
    height: 400px;
    position: relative;
    ${device.xsmall}{
        height: 500px;
    }
    ${device.medium}{
        height: 750px;
    }
    ${device.large}{
        height: 850px;
    }
    ${device.xlarge}{
        height: 980px;
    }
    &:before{
        position: absolute;
        content: '';
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
    }
    &:after{
        position: absolute;
        content: '';
        left: 0;
        bottom: -5px;
        z-index: 1;
        width: 100%;
        height: 100px;
        background: url(${BannerShape}) no-repeat scroll 0 0;
        background-size: 100% 100%;
        ${device.xsmall}{
            height: 160px;
        }
        ${device.small}{
            height: 200px;
        }
        ${device.medium}{
            height: 240px;
        }
        ${device.large}{
            height: 280px;
        }
    }
    .row, .col {
        position: initial
    }
`;

export const HeroBG = styled(BackgroundImage) `
    position: absolute !important;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
`;

export const HeroTextBox = styled.div `
    position: absolute;
    bottom: 152px;
    text-shadow: 2px 3px 6px rgba(0, 0, 0, 0.8);

    ${device.xsmall}{
        bottom: 256px;
    }
    ${device.medium}{
        bottom: 456px;
    }
    ${device.large}{
        bottom: 456px;
    }
    ${device.xlarge}{
        bottom: 586px;
    }
    * {
        position: relative;
        z-index: 9;
    }
`; 